import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import {DataTable}from 'primereact/datatable';
import {Column}from 'primereact/column';
import {Button}from 'primereact/button';
import {Toolbar}from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Chart } from 'primereact/chart';
export default class Principal extends Component {

    constructor(props) {
        super(props);
        this.state={
            value:"",
            data:[],
            ds:0.0,
            promedio:0.0,
            n:0,
            basicData:{
                labels: [],
                datasets: [
                {
                    label: '1DS',
                    data: [],
                    fill: false,
                    borderColor: '#66BB6A',
                    tension: .4
                },
                
                {
                    label: '2DS',
                    data: [],
                    fill: false,
                    borderColor: '#FFA726',
                    tension: .4
                },
                {
                    label: '3DS',
                    data: [],
                    fill: false,
                    borderColor: '#FF6384',
                    tension: .4
                },
                {
                    label: 'Promedio',
                    data: [],
                    fill: false,
                    borderColor: '#000000',
                    tension: .4
                },
                {
                    label: '-1DS',
                    data: [],
                    fill: false,
                    borderColor: '#66BB6A',
                    tension: .4
                },
                
                {
                    label: '-2DS',
                    data: [],
                    fill: false,
                    borderColor: '#FFA726',
                    tension: .4
                },
                {
                    label: '-3DS',
                    data: [],
                    fill: false,
                    borderColor: '#FF6384',
                    tension: .4
                },
                {
                    label: 'Datos',
                    data: [],
                    fill: false,
                    borderColor: '#7E57C2',
                    tension: .4
                }
            ]},   
        }
        this.onBtnAgregar=this.onBtnAgregar.bind(this);
        this.onBtnAgregar2=this.onBtnAgregar2.bind(this);
        this.leftToolbarTemplate=this.leftToolbarTemplate.bind(this);
        this.onChangeInputTextarea=this.onChangeInputTextarea.bind(this);
        var basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
    }
    onBtnAgregar2(e){
        this.onBtnAgregar(e,"");
        this.setState({value:""})
    }
    onBtnAgregar(e,value){
        var contenido=value;
        contenido=contenido.trim();
        var lineas=[];
        if(contenido.length > 0){
            lineas=contenido.split("\n");
        }
        
        var n=0;
        var suma=0;
        var labels=[]
        var data=[]
        for(var i=0;i<lineas.length;i++){
            suma=suma+parseFloat(lineas[i]);
            data.push(parseFloat(lineas[i]));
            n=n+1;
            labels.push(n);
        }
        const prom = (suma / n);
        const promred = prom.toFixed(2);
        
        var dif=[];
        for(var i=0;i<lineas.length;i++){
            dif.push((parseFloat(lineas[i])-promred).toFixed(2));            
        }
        var sumads=parseFloat(0.0);
        for(var i=0;i<dif.length;i++){
            sumads=parseFloat(sumads)+parseFloat(Math.pow(dif[i],2).toFixed(2));
        }
        console.log(sumads);
        var ds=parseFloat(Math.pow((sumads/n),1/2).toFixed(2));
        console.log("DS: "+ds);
        var data1ds=[];
        var data2ds=[];
        var data3ds=[];
        var datan1ds=[];
        var datan2ds=[];
        var datan3ds=[];
        var dataprom=[];
        for (var i=0;i<n;i++){
            data1ds.push(parseFloat(promred)+1*ds);
            data2ds.push(parseFloat(promred)+2*ds);
            data3ds.push(parseFloat(promred)+3*ds);
            datan1ds.push(parseFloat(promred)-1*ds);
            datan2ds.push(parseFloat(promred)-2*ds);
            datan3ds.push(parseFloat(promred)-3*ds);
            dataprom.push(promred)
        }
        var aux={
            labels: labels,
            datasets: [
            {
                label: '1DS',
                data: data1ds,
                fill: false,
                borderColor: '#66BB6A',
                tension: .4
            },
            
            {
                label: '2DS',
                data: data2ds,
                fill: false,
                borderColor: '#FFA726',
                tension: .4
            },
            {
                label: '3DS',
                data: data3ds,
                fill: false,
                borderColor: '#FF6384',
                tension: .4
            },
            {
                label: 'Promedio',
                data: dataprom,
                fill: false,
                borderColor: '#000000',
                tension: .4
            },
            {
                label: '-1DS',
                data: datan1ds,
                fill: false,
                borderColor: '#66BB6A',
                tension: .4
            },
            
            {
                label: '-2DS',
                data: datan2ds,
                fill: false,
                borderColor: '#FFA726',
                tension: .4
            },
            {
                label: '-3DS',
                data: datan3ds,
                fill: false,
                borderColor: '#FF6384',
                tension: .4
            },
            {
                label: 'Datos',
                data: data,
                fill: false,
                borderColor: '#7E57C2',
                tension: .4
            }
            ]};
        
        this.setState({
            basicData:aux,
            promedio:promred,
            n:n,
            ds:ds,
            data:data
        })
    }
    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Calcular" icon="pi pi-plus" className="p-button-success mr-2" onClick={(e)=>this.onBtnAgregar(e,this.state.value)} />
                <Button label="Limpiar" icon="pi pi-plus" className="p-button-danger mr-2" onClick={(e)=>this.onBtnAgregar2(e)} />
            </React.Fragment>
        )
    }
    onChangeInputTextarea(e){
        this.setState({value:e.target.value})
    }

    render() {
        return (
            <div>
                <h5></h5>
                <Panel header="Control de Calidad Quimica Sanguinea">
                    <div className ="grid">                                                
                        <div className="col-4">
                            <div className="grid">
                                <div className="col-12">
                                    <Panel header="Datos">
                                        <InputTextarea  style={{width:"100%"}}rows={5} cols={60} value={this.state.value} onChange={(e) => this.onChangeInputTextarea(e)} />
                                        <Toolbar left={this.leftToolbarTemplate}/>
                                    </Panel>
                                </div>
                                <div className="col-12">
                                    <Panel header="Resultados">
                                        
                                        {this.state.n &&
                                            <div>
                                                <p><b>Promedio: </b>{this.state.promedio}</p>
                                                <p><b>Elementos(n): </b>{this.state.n}</p>
                                                <p><b>DS: </b>{this.state.ds}</p>
                                                <p><b>+1DS: </b>{this.state.promedio} + {this.state.ds} = {(parseFloat(this.state.promedio) + parseFloat(this.state.ds)).toFixed(2)}</p>
                                                <p><b>+2DS: </b>{this.state.promedio} + 2 * {this.state.ds} = {(parseFloat(this.state.promedio) + 2*parseFloat(this.state.ds)).toFixed(2)}</p>
                                                <p><b>+3DS: </b>{this.state.promedio} + 3 * {this.state.ds} = {(parseFloat(this.state.promedio) + 3*parseFloat(this.state.ds)).toFixed(2)}</p>
                                                <p><b>-1DS: </b>{this.state.promedio} - {this.state.ds} = {(parseFloat(this.state.promedio) - parseFloat(this.state.ds)).toFixed(2)}</p>
                                                <p><b>-2DS: </b>{this.state.promedio} - 2 * {this.state.ds} = {(parseFloat(this.state.promedio) - 2* parseFloat(this.state.ds)).toFixed(2)}</p>
                                                <p><b>-3DS: </b>{this.state.promedio} - 3 * {this.state.ds} = {(parseFloat(this.state.promedio) - 3*parseFloat(this.state.ds)).toFixed(2)}</p>
                                            </div>
                                        }
                                        
                                    </Panel>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <Panel header="Grafica de Levey Jennings">
                                <Chart type="line" data={this.state.basicData} options={this.basicOptions} />
                            </Panel>
                        </div>
                    </div>
                </Panel>               
            </div>
        )
    }
}